import { SET_USER, SET_TOKEN, SET_RESOURCES } from './actionType'

export const setUser = (data) => ({
  type: SET_USER,
  data
})

export const setToken = (toekn) => ({
  type: SET_TOKEN,
  toekn
})

export const setResources = (data) => ({
  type: SET_RESOURCES,
  data
})
