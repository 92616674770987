import React, { useState, useEffect } from 'react';
import { Layout, Menu, Row, Breadcrumb } from 'antd';
import { MENUS } from '../utils/menus'
// import { PieChartOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom'
import HeaderTab from '../components/HeaderTab'
import '../static/css/layout.scss'
import logo from '../static/images/logo.jpg'

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

const Sidebar = (props) => {
  const history = useHistory()
  const [collapsed, setCollapsed] = useState(false)
  const [openKeys, setOpenKeys] = useState([])
  const [selectKey, setSelectKey] = useState('')
  const [routerName, setRouterName] = useState('')
  const [routerParentName, setRouterParentName] = useState('')

  // 匹配当前路由选择
  useEffect(() => {
    const { pathname } = history.location
    const splitArr = pathname.split('/')
    const parentPath = splitArr.length - 2 ? '/' + splitArr[splitArr.length - 2] : ''
    const childrenPath = splitArr.length - 1 ?'/' + splitArr[splitArr.length - 1] : 'index'
    setOpenKeys([parentPath])
    setSelectKey([parentPath + childrenPath])

    // 匹配路由名字
    MENUS.forEach(item => {
      if (item.path === parentPath && item.children) {
        setRouterParentName(item.label)
        item.children.forEach(child => {
          if (child.path === childrenPath) {
            setRouterName(child.label)
            document.title = `作业后台${child.label ? '-'+child.label : ''}`
          }
        })
      }
    })
  }, [history])

  // 收缩放菜单
  const onCollapse = e => {
    setCollapsed(!collapsed);
  };

  // 点击菜单
  const onMenuItem = (path, parent, routerName, parentName) => {
    history.push({pathname: `/admin${parent}${path ? path : ''}`})
    setRouterParentName(parentName)
    setRouterName(routerName)
    document.title = `作业后台${routerName ? '-'+routerName : ''}`
  }

  // 点击菜单显示隐藏其他项
  const onOpenChange = keys => {
    if (keys.length > 1) {
      const openKey = keys[keys.length - 1]
      setOpenKeys([openKey])
    } else {
      setOpenKeys(keys)
    }
  }

  // 点击菜单
  const onClickMenu = (e) => {
    setSelectKey(e.key)
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider collapsed={collapsed} style={{ padding: '10px 0' }}>
      {/* <Sider collapsible collapsed={collapsed} onCollapse={onCollapse} style={{ padding: '10px 0' }}> */}
        <Row align='middle' justify='center' style={{ cursor: 'pointer' }} onClick={() => { onMenuItem('/admin') }}>
          <img className="logo" src={logo} alt="logo" />
        </Row>
        { <Menu
          theme="dark"
          openKeys={openKeys}
          selectedKeys={selectKey}
          mode="inline"
          onOpenChange={onOpenChange}
          onClick={onClickMenu}
        >
          {/* <Menu.Item key="/admin/dashboard" icon={<PieChartOutlined />} onClick={() => { onMenuItem('/admin') }}>
            首页
          </Menu.Item> */}
          {
            MENUS.map(item => {
              return (
                <SubMenu
                  key={item.path}
                  icon={item.icon}
                  title={item.label}
                >
                  {
                    item.children.map(child => {
                      return (
                        <Menu.Item
                          key={item.path + child.path}
                          onClick={() => { onMenuItem(child.path, item.path, child.label, item.label) }}
                        >
                          { child.label }
                        </Menu.Item>
                      )
                    })
                  }
                </SubMenu>
              )
            })
          }
        </Menu>}
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0 }}>
          <HeaderTab collapsed={collapsed} onCollapse={onCollapse} />
        </Header>
        <Content style={{ margin: '0 16px' }}>
          <Breadcrumb style={{ margin: '16px 0' }}>
            <Breadcrumb.Item>首页</Breadcrumb.Item>
            {
              routerParentName && <Breadcrumb.Item>{ routerParentName }</Breadcrumb.Item>
            }
            {
              routerName && <Breadcrumb.Item>{ routerName }</Breadcrumb.Item>
            }
          </Breadcrumb>
          <div className="site-layout-background" style={{ padding: '5px 15px', minHeight: 360 }}>
            { props.children }
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          <div style={{ }}>技术提供 ©2020 作业管理系统</div>
        </Footer>
      </Layout>
    </Layout>
  );
}

export default Sidebar