import axios from 'axios'

/**
 * 登录
 * @param {Object} data { username, password, scope, requestId, code } scope:PC/H5/iOS/Android
 */
export function sign(data) {
  return axios.post(`/sign/in`, data)
}

/**
 * 登录
 * @param {Object} data { width, height }
 */
export function find_captcha(data) {
  return axios.get(`/sign/captcha`, data)
}

export default {
  sign,
  find_captcha
}
