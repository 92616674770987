import React from 'react';
// import enUS from 'antd/es/locale/en_US';
import zhCN from 'antd/es/locale/zh_CN';
import { ConfigProvider } from 'antd'

function Language(props) {
  return (
    <ConfigProvider locale={zhCN}>
      { props.children }
    </ConfigProvider>
  );
}

export default Language