import React from 'react';
import ReactDOM from 'react-dom';
import './static/css/index.scss';
import Root from './router';
import Language from './lang'
import 'antd/dist/antd.css'
import './utils/request'
import store from './store'
import { Provider } from 'react-redux'
import { HashRouter as Router } from "react-router-dom";
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Language>
        <Root />
      </Language>
    </Router>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
