import axios from 'axios'
import { message } from 'antd'
import cookie from 'react-cookies'
import { TOKEN } from '../utils/constants'

axios.defaults.baseURL = process.env.REACT_APP_BASE_API
// axios.defaults.baseURL = 'http://8.129.38.99:11001'
axios.defaults.timeout = 5000
axios.defaults.headers['Content-Type'] = 'application/json'

// const service = axios.create({
//   baseURL: process.env.REACT_APP_BASE_API, // url = base url + request url
//   // withCredentials: true, // send cookies when cross-domain requests
//   timeout: 5000, // request timeout
//   headers: { 'Content-Type': 'application/json' }
// })

// request interceptor
axios.interceptors.request.use(
  config => {
    const token = cookie.load(TOKEN) || ''
    config.headers['Authorization'] = token
    return config
  },
  error => {
    return Promise.reject(error)
  }
)


// 响应拦截
axios.interceptors.response.use(
  /**
   * 下面的注释为通过在response里，自定义code来标示请求状态
   * 当code返回如下情况则说明权限有问题，登出并返回到登录页
   * 如想通过 XMLHttpRequest 来状态码标识 逻辑可写在下面error中
   * 以下代码均为样例，请结合自生需求加以修改，若不需要，则可删除
   */
  response => {
    const code = response.data.code
    if (code === 200) {
    // Message.closeAll()
      // Message({ message: response.data.msg, type: 'success', showClose: true })
      return response.data
    } else {
      handleError(code, response.data)
    }
  },
  error => {
    if (error.response) {
      const { code } = error.response
      handleError(code, error.response.data)
    } else {
      message.destroy()
      return message.error({ content: '服务器异常', key: 'sever_error' })
    }
    return Promise.reject(error)
  }
)


/**
 * 处理错误
 * @param code 代码
 * @param data  数据
 */
function handleError (code, data) {
  message.destroy()

  switch (code) {
    case 1000:
      message.error('未授权')
      // Route.push({ pathname: '/login' })
      break

    case 1202:
      message.error('账户或密码错误')
      break

    case 21013:
      message.error('验证码错误')
      break

    default:
      message.error('服务器错误')
      break
  }
}

// export default service
