import { SET_USER, SET_TOKEN, SET_RESOURCES } from './actionType'
const defaultState = {
  user: {
    avatar: '1',
    name: '123',
    id: ''
  },
  resources: [],
  token: ''
}

export default (state = defaultState, action) => {
  const newState = JSON.parse(JSON.stringify(state))

  switch(action.type) {
    case SET_USER:
      newState.user = action.data
      break

    case SET_TOKEN:
      newState.token = action.token
      break

    case SET_RESOURCES:
      newState.resources = action.data
      break

    default:
      return newState
  }

  return newState
}