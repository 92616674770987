import React from 'react';
import { createFromIconfontCN  } from '@ant-design/icons'

const MyIcon = createFromIconfontCN({
  scriptUrl: ['//at.alicdn.com/t/font_2176454_jfeuy1hqrxs.js'], // 在 iconfont.cn 上生成
})

export const MENUS = [
  { label: '学校管理', name: 'School', icon: (<MyIcon type="icon-xuexiaoguanli--" />), path: '/school', children: [
    { label: '学校管理', name: 'SchoolManage', path: '/schools' },
    { label: '职位管理', name: 'SchoolPositions', path: '/positions' },
    { label: '年级管理', name: 'SchoolGrade', path: '/grade' },
    { label: '学科管理', name: 'SchoolSubject', path: '/subject' },
    { label: '专业管理', name: 'SchoolMajors', path: '/majors' },
    { label: '班级管理', name: 'SchoolClass', path: '/class' },
    { label: '教师管理', name: 'SchoolTeacher', path: '/teacher' },
    { label: '学生管理', name: 'SchoolStudent', path: '/student' },
    { label: '家长管理', name: 'SchoolParent', path: '/parent' }
  ] },
  { label:'教辅管理', name: 'Tutorials', icon: (<MyIcon type="icon-jiaofuguanli" />), path: '/tutorials', children: [
    { label: '教辅管理', name: 'TutorialsBook', path: '/tutorials', notExact: true },
    { label: '学校教辅', name: 'TutorialsSchool', path: '/school' },
    { label: '班级教辅', name: 'TutorialsCleasses', path: '/classes' },
    { label: '知识点', name: 'Knowledge', path: '/knowledge' }
  ] },
  { label:'作业管理', name: 'Homework', icon: (<MyIcon type="icon-40banjizuoyewai" />), path: '/homework', children: [
    { label: '班级作业', name: 'HomeworkClass', path: '/class' },
    { label: '学生作业', name: 'HomeworkStudent', path: '/student' }
  ] },
  { label:'账户管理', name: 'Account', icon: (<MyIcon type="icon-zhanghuguanli1" />), path: '/account', children: [
    { label: '账户管理', name: 'AccountAccount', path: '/account' },
    { label: '家长管理', name: 'AccountParent', path: '/parent' },
    { label: '登录日志', name: 'AccountLoginLog', path: '/loginLog' }
  ] },
  { label:'系统管理', name: 'System', icon: (<MyIcon type="icon-guanli4" />), path: '/system', children: [
    { label: '账户管理', name: 'SystemAccount', path: '/account' },
    { label: '角色管理', name: 'Role', path: '/role' },
    { label: '资源管理', name: 'SystemResources', path: '/resources' },
    { label: '数据字典', name: 'SystemDictionary', path: '/dictionary' },
    { label: '区域管理', name: 'SystemArea', path: '/area' },
    { label: '配置管理', name: 'SystemConfig', path: '/config' },
    { label: '文件管理', name: 'SystemFile', path: '/file' },
    { label: '登录日志', name: 'SystemLoginLog', path: '/loginLog' },
    { label: '回收站', name: 'SystemRecycle', path: '/recycle' }
  ] },
]
