import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Form, Input, Button, Spin, Row, Col, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useHistory  } from 'react-router-dom'
import { TOKEN } from '../../utils/constants'
import cookie from 'react-cookies'
import '../../static/css/login.scss'
import API from '../../api/login/sign'
// import logo from '../../static/images/loginLogo.png'
import login_pic from '../../static/images/login_pic.png'
import login_pic2 from '../../static/images/login_pic2.png'

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
const captchaSize = {
  width: 100,
  height: 40
}

const Login = (props) => {
  const formRef = useRef()
  const history = useHistory()
  const [captcha, setCaptcha] = useState('')
  const [requestId, setRequestId] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [isCaptchaLoading, setCaptchaLoading] = useState(false)

  // 判断是否存在cookie,为true跳转首页
  const handleHashToken = useCallback(() => {
    const token = cookie.load(TOKEN)
    // token && history.push({ pathname: '/admin/dashboard' })
    token && history.push({ pathname: '/admin/tutorials/tutorials' })
  }, [history])

  useEffect(() => {
    handleHashToken()
  }, [handleHashToken])

  // 获取验证码
  const hendleGetCaptcha = useCallback(() => {
    setCaptchaLoading(true)
    API.find_captcha(captchaSize).then(response => {
      if (response?.code === 200) {
        const { captcha, requestId } = response.data
        setCaptcha(captcha)
        setRequestId(requestId)
      }
    }).finally(() => {
      setCaptchaLoading(false)
    })
  }, [])

  useEffect(() => {
    hendleGetCaptcha()
  }, [hendleGetCaptcha])

  const onFinish = values => {
    const { username, password, code } = values
    const param = { username, password, code, requestId, scope: 'PC' }
    setLoading(true)
    API.sign(param).then(response => {
      if (response?.code === 200) {
        const { access_token } = response.data
        const info = { username, avatar: '', name: '' }
        cookie.save(TOKEN, access_token)
        cookie.save('info', info)
        // history.push('/admin/dashboard')
        history.push('/admin/tutorials/tutorials')
        message.success('登录成功')
      }
    }).finally(() => {
      setLoading(false)
    })
    // setTimeout(() => {
    //   cookie.save(TOKEN, values.username)
    //   formRef.current.resetFields()
    //   history.push('/admin/dashboard')
    //   setLoading(false)
    // }, 500);
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };


  return (
    <Spin
      indicator={antIcon}
      size="large"
      spinning={isLoading}
    >
      <div className="login">
        <Row align='middle' justify='center' className='login-card'>
          <div className='login-circle'>
            <img src={login_pic2} alt="login_pic2" style={{ width: 34, height: 34 }} />
          </div>
          <Col span={12}  className='login-left'>
            <img src={login_pic} alt="login_pic" className="login-pic" />
          </Col>
          <Col span={12}  className='login-right'>
            <div className="login-title">后台登录</div>
            <Form
              ref={formRef}
              name="basic"
              // initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                name="username"
                rules={[{ required: true, message: '请输入用户名!' }]}
                hasFeedback
              >
                <Input className='login-input' allowClear bordered={false} placeholder="用户名" />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[{ required: true, message: '请输入密码!' }]}
                hasFeedback
              >
                <Input.Password className='login-input' allowClear bordered={false} placeholder="密码" />
              </Form.Item>

              <Row align='top' justify='space-between'>
                <Col span={12}>
                  <Form.Item
                    name="code"
                    rules={[{ required: true, message: '请输入验证码!' }]}
                    hasFeedback
                  >
                    <Input className='login-input' allowClear bordered={false} maxLength={4} placeholder="验证码" />
                  </Form.Item>
                </Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                  <Spin spinning={isCaptchaLoading}>
                    <img
                      className='pointer login-captcha'
                      style={captchaSize}
                      src={`data:image/png;base64,${captcha}`}
                      alt='captcha'
                      onClick={hendleGetCaptcha}
                    />
                  </Spin>
                </Col>
              </Row>

              <Form.Item>
                <Button className="login-btn" type="primary" htmlType="submit">
                  立即登录
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    </Spin>
  );
};

export default Login