export const TOKEN = 'ADMIN_TOKEN'

export const MAP_KEY = '21a7e40b62ba074f165ea76d3ae126d4'

/**
 * 对话框-小
 */
export const DIALOG_SMALL = '400px'

/**
 * 对话框-中
 */
export const DIALOG_MEDIUM = '750px'

/**
 * 对话框-大
 */
export const DIALOG_BIG = '70%'

/**
 * 启用禁用
 */
export const DISSABLED_TYPE = [
  { label: '启用', value: 0, color: '#108ee9' },
  { label: '禁用', value: 1, color: '#f50' }
]

/**
 * 成功失败
 */
export const SUCCESS_TYPE = [
  { label: '失败', value: 0, color: '#f50' },
  { label: '成功', value: 1, color: '#108ee9' }
]
/**
 * 是 否
 */
export const YES_NO = [
  { label: '否', value: 0, color: '#f50' },
  { label: '是', value: 1, color: '#108ee9' }
]

/**
 * 性别
 */
export const GENDER_TYPE = [
  { label: '未知', value: 'N', color: 'cyan' },
  { label: '男性', value: 'M', color: '#108ee9' },
  { label: '女性', value: 'F', color: 'gold' }
]

/**
 * 系统登录方式
 */
export const LOGIN_TYPE = [
  { label: '用户名', value: 1, color: 'cyan' },
  { label: '手机号码', value: 2, color: '#108ee9' },
  { label: '电子邮箱', value: 3, color: 'gold' }
]

/**
 * 系统资源
 */
export const SYSTEM_RESOURCE = [
  { label: '组', value: 'Group', color: 'green' },
  { label: '模块', value: 'Module', color: 'cyan' },
  { label: '菜单', value: 'Menu', color: '#108ee9' },
  { label: '功能', value: 'Function', color: 'gold' }
]

/**
 * 账户登录方式
 */
export const ACCOUNT_LOGIN_TYPE = [
  { label: '用户名', value: 1, color: 'cyan' },
  { label: '手机号码', value: 2, color: '#108ee9' },
  { label: '电子邮箱', value: 3, color: 'gold' },
  { label: '证件号码', value: 4, color: 'green' }
]

/**
 * 权限
 */
export const PERMISSIONS_TYPE = [
  { label: '教师', value: 't', color: 'gold' },
  { label: '学生', value: 's', color: '#2db7f5' },
  { label: '家长', value: 'p', color: '#108ee9' },
  { label: '其他', value: 'o', color: 'purple' }
]

/**
 * 值类型
 */
export const CONFIG_TYPE = [
  { label: 'Number', value: 'Number', color: '#f50' },
  { label: 'Double', value: 'Double', color: '#2db7f5' },
  { label: 'Boolean', value: 'Boolean', color: '#87d068' },
  { label: 'String', value: 'String', color: '#108ee9' },
  { label: 'Date', value: 'Date', color: 'purple' },
  { label: 'DataTime', value: 'DataTime', color: 'green' },
  { label: 'Map', value: 'Map', color: 'cyan' },
  { label: 'List', value: 'List', color: 'gold' },
]

/**
 * 证件类型
 */
export const CARD_TYPE = [
  { label: '身份证', value: '01', color: '#108ee9' },
  { label: '护照', value: '02', color: '#87d068' },
  { label: '军(警)官证', value: '03', color: 'gold' },
  { label: '士兵证', value: '04', color: 'green' },
  { label: '台胞证', value: '05', color: 'blue' },
  { label: '反乡证', value: '06', color: 'cyan' },
  { label: '其他', value: '09', color: 'geekblue' }
]

/**
 * 职位类型
 */
export const POSITION_TYPE = [
  { label: '学校', value: 1, color: 'cyan' },
  { label: '年级', value: 2, color: '#108ee9' },
  { label: '学科', value: 3, color: 'gold' }
]

/**
 * 教辅类型
 */
export const TUTORIALS_TYPE = [
  { label: '常规', value: 1, color: '#108ee9' },
  { label: '自设', value: 2, color: '#87d068' }
]

/**
 * 教辅类型
 */
export const TUTORIALS_IMAGE_TYPE = [
  { label: '简介', value: 1, color: '#108ee9' },
  { label: '目录', value: 2, color: '#87d068' },
  { label: '正文', value: 3, color: 'gold' },
  { label: '其他', value: 4, color: 'cyan' }
]

/**
 * 教辅问题类型
 */
export const TUTORIALS_QUESTION_TYPE = [
  { label: '单选', value: '01', color: '#108ee9' },
  { label: '多选', value: '02', color: '#87d068' },
  { label: '判断', value: '03', color: 'gold' },
  { label: '填空', value: '04', color: 'green' },
  { label: '问答', value: '05', color: 'blue' },
  { label: '问题', value: '06', color: 'cyan' },
  { label: '其他', value: '99', color: 'geekblue' }
]

/**
 * 教辅问题答案类型
 */
export const TUTORIALS_ANSWER_TYPE = [
  { label: '文字', value: 1, color: '#108ee9' },
  { label: '图片', value: 2, color: '#87d068' },
]

/**
 * 学校作业状态
 */
export const STUDENT_WORKS_STATE = [
  { label: '待完成 ', value: 1, color: 'geekblue' },
  { label: '写作中', value: 2, color: 'green' },
  { label: '已完成', value: 3, color: '#108ee9' },
  { label: '批改中', value: 4, color: 'cyan' },
  { label: '已批改', value: 4, color: 'blue' }
]

/**
 * 批改方式
 */
export const CORRECT_MODE = [
  { label: '按题目', value: 1, color: 'geekblue' },
  { label: '按学生', value: 2, color: 'cyan' }
]

/**
 * 问题轨迹类型
 */
export const QUESTION_TRAJECTORIES_TYPE = [
  { label: '答题', value: 1, color: 'geekblue' },
  { label: '订错', value: 2, color: 'cyan' }
]

/**
 * 班级作业批改方式
 */
export const CLASS_WORK_CORRECT_MODE = [
  { label: '逐个学生', value: 1, color: 'geekblue' },
  { label: '逐个题目', value: 2, color: 'cyan' }
]

/**
 * 文件类型	MIME 类型
 * https://developer.mozilla.org/zh-CN/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
 */
export const FILE_TYPE = [
  { label: 'image/png', value: 'image/png' },
  { label: 'image/jpeg', value: 'image/jpeg' },
  { label: 'image/apng', value: 'image/apng' },
  { label: 'image/bmp', value: 'image/bmp' },
  { label: 'image/gif', value: 'image/gif' },
  { label: 'image/x-icon', value: 'image/x-icon' },
  { label: 'image/svg+xml', value: 'image/svg+xml' },
  { label: 'image/tiff', value: 'image/tiff' },
  { label: 'image/webp', value: 'image/webp' },
  { label: 'audio/aac', value: 'audio/aac' },
  { label: 'application/x-abiword', value: 'application/x-abiword' },
  { label: 'application/x-freearc', value: 'application/x-freearc' },
  { label: 'video/x-msvideo', value: 'video/x-msvideo' },
  { label: 'application/vnd.amazon.ebook', value: 'application/vnd.amazon.ebook' },
  { label: 'application/octet-stream', value: 'application/octet-stream' },
  { label: 'image/bmp', value: 'image/bmp' },
  { label: 'application/x-bzip', value: 'application/x-bzip' },
  { label: 'application/x-bzip2', value: 'application/x-bzip2' },
  { label: 'application/x-csh', value: 'application/x-csh' },
  { label: 'text/css', value: 'text/css' },
  { label: 'text/csv', value: 'text/csv' },
  { label: 'application/msword', value: 'application/msword' },
  { label: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', value: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' },
  { label: 'application/vnd.ms-fontobject', value: 'application/vnd.ms-fontobject' },
  { label: 'application/epub+zip', value: 'application/epub+zip' },
  { label: 'text/html', value: 'text/html' },
  { label: 'image/vnd.microsoft.icon', value: 'image/vnd.microsoft.icon' },
  { label: 'text/calendar', value: 'text/calendar' },
  { label: 'application/java-archive', value: 'application/java-archive' },
  { label: 'text/javascript', value: 'text/javascript' },
  { label: 'application/json', value: 'application/json' },
  { label: 'audio/mpeg', value: 'audio/mpeg' },
  { label: 'video/mpeg', value: 'video/mpeg' },
  { label: 'image/svg+xml', value: 'image/svg+xml' },
  { label: 'image/tiff', value: 'image/tiff' },
  { label: 'text/plain', value: 'text/plain' },
  { label: 'font/ttf', value: 'font/ttf' },
  { label: 'audio/wav', value: 'audio/wav' },
  { label: 'audio/webm', value: 'audio/webm' },
  { label: 'video/webm', value: 'video/webm' },
  { label: 'image/webp', value: 'image/webp' },
  { label: 'font/woff', value: 'font/woff' },
  { label: 'font/woff2', value: 'font/woff2' },
  { label: 'application/xhtml+xml', value: 'application/xhtml+xml' },
  { label: 'application/vnd.ms-excel', value: 'application/vnd.ms-excel' },
  { label: 'application/zip', value: 'application/zip' },
  { label: 'video/3gpp', value: 'video/3gpp' },
  { label: 'video/3gpp2', value: 'video/3gpp2' },
  { label: 'application/x-7z-compressed', value: 'application/x-7z-compressed' }
]