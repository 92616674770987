import axios from 'axios'

/**
 * 新增
 * @param {Object} data
 */
export function create(data) {
  return axios.post(`/sysAccounts`, data)
}

/**
 * 修改
 * @param {String} id  主键ID
 * @param {Object} data
 */
export function update(data) {
  return axios.put(`/sysAccounts`, data)
}

/**
 * 删除
 * @param {String} id
 */
export function del(id) {
  return axios.delete(`/sysAccounts`, { params: { id } })
}

/**
 * 批量删除
 * @param {Array} ids
 */
export function del_batch(ids) {
    return axios({
      headers: { 'Content-Type': 'application/json' },
      method: 'delete',
      url: '/sysAccounts/batch',
      data: ids
  })
}

/**
 * 查询-根据主键查询
 * @param {String} id
 */
export function find_one(id) {
  return axios.get(`/sysAccounts`, { params: { id } })
}

/**
 * 查询-根据条件分页查询
 * @param {Object} data
 */
export function find_page(data) {
  return axios.get(`/sysAccounts/page`, { params: data })
}

/**
 * 查询-根据条件查询
 * @param {Object} data
 */
export function find_list(data) {
  return axios.get(`/sysAccounts/list`, { params: data })
}

/**
 * 状态切换
 * @param {Number} id
 * @param {String} disable 0 可用 1 禁用
 */
export function update_state(id, disable) {
  return axios.put(`/sysAccounts/${id}/disabled?disabled=${disable}`)
}

/**
 * 统计总数
 * @param {Object} data
 */
export function count(data) {
  return axios.get(`/sysAccounts/count`, data)
}

/**
 * 解冻
 * @param {Object} data
 */
export function unfreeze(id) {
  return axios.post(`/sysAccounts/unfreeze?id=${id}`)
}

/**
 * 重置密码
 * @param {Object} data
 */
export function reset_password(data) {
  return axios.post(`/sysAccounts/resetPassword?id=${data.id}&newPassword=${data.newPassword}`)
}

/**
 * 修改密码
 * @param {Object} data
 */
export function edit_password(data) {
  return axios.post(`/sysAccounts/updatePassword?id=${data.id}&newPassword=${data.newPassword}&oldPassword=${data.oldPassword}`)
}

/**
 * 获取用户信息
 */
export function find_info() {
  return axios.get(`/sysAccounts/info`)
}

/**
 * 保存账户角色
 * @param {Object} data
 */
export function save_roles(data) {
  return axios.post(`/sysAccounts/roles`, data)
}

export default {
  create,
  update,
  del,
  del_batch,
  find_one,
  find_page,
  find_list,
  update_state,
  count,
  unfreeze,
  edit_password,
  reset_password,
  find_info,
  save_roles
}
