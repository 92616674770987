import React, { useEffect } from 'react'
import { Route, Switch, withRouter } from "react-router-dom";
import { TOKEN } from '../utils/constants'
import { MENUS } from '../utils/menus'
import { isEmpty } from '../utils/validate'
import Login from '../pages/login';
import Layout from '../layouts'
// import Dashboard from '../pages/dashboard'
import cookie from 'react-cookies'
import Loadable from 'react-loadable'

const MenuFiles = {}

MENUS.map(item => {
  return item.children.map(child => {
      return MenuFiles[child.name] = Loadable({
        loader: () => import(`../pages${item.path}${child.path}`),
        loading() {
            return <div>正在加载...</div>
        }
      })
    }
  )
})

function Root(props) {
  const whitelist = ['/login'] // 白名单

  useEffect(() => {
    const token = cookie.load(TOKEN)
    const pathname = props.location.pathname
    if (!whitelist.some(item => item === pathname) && isEmpty(token)) {
      props.history.push({ pathname: '/login' })
    }
  }, [props, whitelist])

  return (
    <Switch>
      <Route exact component={Login} path='/login' />
      <Route path="/admin" render={() =>
        <Layout>
          {/* <Route exact component={Dashboard} path='/admin/dashboard' /> */}
          {
            MENUS.map(item => {
              return item.children.map(child =>
                <Route
                  key={child.path}
                  exact={!child.notExact}
                  component={MenuFiles[child.name]}
                  path={`/admin${item.path}${child.path}`}
                />
              )
            })
          }
        </Layout>
      }></Route>
      <Route component={Login} />
    </Switch>
  )
}

export default withRouter(Root)