import React, { useState, useEffect } from 'react';
import { Spin, Menu, Dropdown, Row } from 'antd'
import { connect } from 'react-redux'
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";
import { TOKEN } from '../../utils/constants'
import { setToken, setResources } from '../../store/actionFun'
import cookie from 'react-cookies'
import '../../static/css/header.scss'
import logo from '../../static/images/logo.png'
import API from '../../api/sys/sysAccounts'
import store from '../../store'

function HeaderTab({ collapsed, onCollapse, handleSetToken, handleSetResources }) {
  const history = useHistory()
  const info = cookie.load('info')
  const token = cookie.load(TOKEN)
  const avatar = info?.avatar ? info.avatar : logo
  const name = info?.name ? info.name : 'WEGO'
  const [isLoading, setLoading] = useState(false)
  const [lock, setLock] = useState(false)

  // 当没有token使时退出登录
  useEffect(() => {
    if (!token) {
      history.push({ pathname: '/login' })
    }
  }, [history, token])

  useEffect(() => {
    if (!info?.name && !lock) {
      setLock(true)
      setLoading(true)
      API.find_info().then(response => {
        if (response?.code === 200) {
          const { data } = response
          info.avatar = data?.avatar || ''
          info.name = data?.name || ''
          handleSetResources(data.resources || [])
          cookie.save('info', info)
        }
      }).finally(() => {
        setLoading(false)
      })
    }
  }, [handleSetResources, info, lock])

  // TODO: 逻辑优化
  // 获取权限数据
  useEffect(() => {
    const resources = store.getState().resources
    if (!resources.length) {
      setLoading(true)
      API.find_info().then(response => {
        if (response?.code === 200) {
          const { data } = response
          handleSetResources(data.resources || [])
        }
      }).finally(() => {
        setLoading(false)
      })
    }
  }, [handleSetResources])

  // 退出登录
  const handleLoginout = () => {
    cookie.remove(TOKEN)
    cookie.remove('info')
    handleSetToken('')
    handleSetResources([])
    history.push({ pathname: '/login' })
  }

  // 点击菜单项
  const handleMenuItem = e => {
    switch (e.key) {
      case 'person':
        break

      case 'loginout':
        handleLoginout()
        break

      default:
        break
    }
  }
  
  const menu = (
    <Menu onClick={handleMenuItem}>
      {/* <Menu.Item key="person">
        <div>个人中心</div>
      </Menu.Item>
      <Menu.Divider /> */}
      <Menu.Item key="loginout">
        <div>退出登录</div>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="header-row">
      <div className="header-trigger" onClick={onCollapse}>
        {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined /> }
      </div>
      <Spin spinning={isLoading}>
        <Dropdown overlay={menu} trigger="hover">
          <Row align='middle'>
            <img className="header-avatar" src={avatar} alt="头像" />
            <span className="header-name">{ name }</span>
          </Row>
        </Dropdown>
      </Spin>
    </div>
  )
}

/**
 * TODO: 待删除
 */
const stateToProps = (state) => {
  const { user }  = state
  return {
    user
  }
}

const dispatchToProps = (dispatch) => {
  return {
    handleSetToken(data) {
      const action = setToken(data)
      dispatch(action)
    },
    handleSetResources(data) {
      const action = setResources(data)
      dispatch(action)
    }
  }
}

export default connect(stateToProps, dispatchToProps)(HeaderTab)